<template>
  <v-app class="psw-label">
    <v-app-bar app color="primary" dark height="100">
      <v-container>
        <v-row justify="center">
          <v-col cols="12" md="8" lg="5">
            <div class="d-flex align-center justify-center">
              <img
                alt="Poliuretanos Summa Woodbridge"
                src="@/assets/logo_psw_white.svg"
                style="height: 50px; width: auto"
                class="mr-2"
              />

              <h2 class="mt-2 mx-2 font-weight-light">
                Label <span class="font-weight-medium mx-2">EANSA</span>
              </h2>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-app-bar>

    <v-main>
      <v-container fluid>
        <v-slide-y-transition mode="out-in">
          <router-view></router-view>
        </v-slide-y-transition>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "PSWLabel",
};
</script>
