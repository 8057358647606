<template>
  <div class="home">
    <v-row justify="center" align="center">
      <v-col cols="12" md="8" lg="5">
        <div class="text-center my-4" v-if="debug">
          <v-btn color="primary" @click="simulate">Simulate Scan</v-btn>
        </div>

        <div class="indicator">
          <v-skeleton-loader
            type="avatar"
            v-if="loading.post"
          ></v-skeleton-loader>
          <span v-else class="pulse"></span>
        </div>

        <h1 class="font-weight-light text-blue text-h5 text-center">
          Esperando escaner
        </h1>

        <v-divider></v-divider>

        <v-skeleton-loader v-if="loading.get" type="text@3"></v-skeleton-loader>

        <div v-else>
          <h2
            v-if="entries.length == 0"
            class="text-center font-weight-regular red--text"
          >
            Sin registros de códigos QR.
          </h2>

          <v-timeline v-else>
            <v-timeline-item
              small
              right
              class="mb-c"
              v-for="(entry, i) in entries"
              :key="'pn-' + i"
            >
              <template v-slot:opposite>
                <span class="headline font-weight-bold primary--text"
                  ><qrcode-vue
                    :value="entry.qr_code_data"
                    :size="130"
                  ></qrcode-vue
                ></span>
              </template>
              <div class="py-4">
                <h2 class="headline font-weight-light mb-4 primary--text">
                  {{ entry.part_number }}
                </h2>
                <h3>
                  {{ entry.recorded_at }}
                </h3>
              </div>
            </v-timeline-item>
          </v-timeline>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import moment from "moment";
import QrcodeVue from "qrcode.vue";

export default {
  name: "Home",
  components: { QrcodeVue },
  data: () => ({
    entries: [],
    loading: {
      get: false,
      post: false,
    },
    error: null,
  }),
  computed: {
    debug() {
      let debug = false;

      if (this.$route.query.debug !== undefined) debug = true;

      return debug;
    },
  },
  created() {
    this.$barcodeScanner.init(this.onBarcodeScanned);
    this.fetchQrCodes();
  },
  destroyed() {
    this.$barcodeScanner.destroy();
  },
  methods: {
    simulate() {
      this.insertEntry("0000000000");
    },
    async insertEntry(barcode) {
      this.loading.post = true;

      let recordedAt = moment();

      let payload = {
        part_number: barcode,
        recorded_at: recordedAt.format("YYYY-MM-DD HH:mm:ss"),
      };

      try {
        const { data } = await this.$http.post("/qr-codes", payload);
        this.entries.unshift(data);
      } catch (e) {
        this.error = e;
      }

      this.loading.post = false;
    },
    onBarcodeScanned(barcode) {
      this.insertEntry(barcode);
    },
    resetBarcode() {
      let barcode = this.$barcodeScanner.getPreviousCode();
      console.log(barcode);
    },
    async fetchQrCodes() {
      this.loading.get = true;

      try {
        const { status, data } = await this.$http.get("/qr-codes");

        if (status != 204) {
          this.entries = data.data;
        }
      } catch (e) {
        this.error = e;
      }

      this.loading.get = false;
    },
  },
};
</script>

<style>
.indicator {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: center;
  margin: 30px 0 25px 0;
}

.text-blue {
  color: #167ad8;
}

.pulse {
  display: block;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  background: #167ad8;
  cursor: pointer;
  box-shadow: 5 5 5 rgba(7, 95, 236, 0.4);
  animation: pulse 1.5s infinite;
}

@-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(7, 95, 236, 0.4);
  }
  70% {
    -webkit-box-shadow: 0 0 0 25px rgba(204, 169, 44, 0);
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
  }
}

@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(7, 95, 236, 0.4);
    box-shadow: 0 0 0 0 rgba(7, 95, 236, 0.4);
  }
  70% {
    -moz-box-shadow: 0 0 0 25px rgba(204, 169, 44, 0);
    box-shadow: 0 0 0 25px rgba(204, 169, 44, 0);
  }
  100% {
    -moz-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
    box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
  }
}

.mb-c {
  margin-bottom: 40px;
}

.home .v-divider {
  border: 1px solid #a3d3ff !important;
  margin: 30px 0 30px;
}
</style>
